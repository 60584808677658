<script setup lang="ts">
import debounce from 'lodash-es/debounce'
import omitBy from 'lodash-es/omitBy'
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import FeedControls from '/~/extensions/health/components/feed/feed-controls.vue'
import FeedView from '/~/extensions/health/components/feed/feed-view.vue'
import FeedTabs from '/~/extensions/health/components/tabs.vue'
import { useRecipes } from '/~/extensions/health/composables'
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import HealthEbitsFeedList from './health-ebits-feed-list.vue'

type UString = string | undefined

const route = useRoute()
const router = useRouter()
const { saved, feed, queryState } = useRecipes()
const { page } = useEditablePage()

const categories = [
  { text: 'All Categories', value: 'all' },
  { text: 'Meals', value: 'meals' },
  { text: 'Breakfast', value: 'breakfast' },
  { text: 'Dessert', value: 'dessert' },
  { text: 'Salads', value: 'salads' },
]

const tabs = [
  {
    label: 'Recipes',
    route: { path: 'health-ebits', query: { type: 'recipes' } },
  },
  {
    label: 'Saved recipes',
    route: { path: 'health-ebits', query: { type: 'saved' } },
  },
]

const filters = ref<InstanceType<typeof FeedControls> | null>(null)
const search = ref((route.query?.search as UString) ?? '')
const category = ref<string>((route.query?.category as UString) ?? 'all')

const type = computed(() => (route.query?.type as UString) ?? 'recipes')

const params = computed(() => {
  return { search: search.value, category: category.value }
})

async function getData() {
  feed.reset()

  await feed.getData({
    search: {
      filters: [
        type.value === 'saved'
          ? saved.value.map((id) => 'objectID:' + id).join(' OR ') ||
            'objectID:null'
          : '',
        category.value && category.value !== 'all'
          ? `Category:${category.value}`
          : '',
      ]
        .filter((i) => Boolean(i))
        .join(' AND '),
      query: search.value,
    },
  })
}

const onSearch = debounce(function (value) {
  search.value = value
  getData()
  setRoute()
}, 350)

function changeCategory(newCategory: string) {
  category.value = newCategory
  getData()
  setRoute()
}

function setRoute() {
  const query = omitBy(
    { search: search.value, category: category.value, type: type.value },
    (v) => !v
  )

  router.replace({ query })
  queryState.value = query
}

watch(type, (newType, oldType) => {
  if (newType !== oldType) {
    feed.reset()
    search.value = ''
    category.value = ''
    if (filters.value) {
      filters.value.resetCategory()
      filters.value.search = ''
    }
    getData()
  }
})

onMounted(() => {
  getData()
})
</script>

<template>
  <div class="mx-auto max-w-7xl">
    <feed-view>
      <template #hero>
        <feed-tabs :tabs="tabs" :type="type" class="sm:hidden" />

        <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />
      </template>

      <template #tabs>
        <feed-tabs :tabs="tabs" :type="type" class="hidden sm:block" />
      </template>

      <template #controls>
        <feed-controls
          ref="filters"
          :categories="categories"
          :params="params"
          placeholder="Search for a recipe..."
          @input="onSearch"
          @category-update="changeCategory"
        />
      </template>

      <health-ebits-feed-list :type="type" :feed="feed" />
    </feed-view>
  </div>
</template>
